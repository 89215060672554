.App .navbar-expand{
  justify-content: flex-start;
  background-color: black;
}

.alstroemeria-logo{
  width: 110px;
}


.home-div{
  background-image: url("./alstroemeriaBG.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 700px;
}


.home-div-header{
  max-width: 900px;
  padding-left: 120px;
  padding-top: 50px;
  text-align: center;
}

.about-us-sec{
  padding-left: 50px;
  padding-right: 50px;
}


.card-class-sec{
  padding-top: 50px;
}

.card-sec-div{
  display: flex;
}

.info-sec{
  padding-left: 35px;
}

.about-us-hed{
  margin-bottom: 25px;
}

.astrolmeria-span{
 text-transform: uppercase;
 color: red;
 padding-bottom: 10px;
}


.App .language-btn{
  background-color: transparent;
  border: none;
}


@media only screen and (max-width: 768px) {
.home-div-header{
    padding-left: 30px;
padding-right: 30px;
    font-size: 20px;
}
}
